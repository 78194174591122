import { ref, set } from "firebase/database";
import {
  removeScheduledByClientPhone,
  scheduleSequence,
} from "../../schedule/schedule.service";
import { firebaseDb } from "../../firebase/firebase";
import { globalAppState, namedStore } from "../../store";
import styled from "styled-components";
import { useMemo, useState } from "react";
import moment from "moment-timezone";
import { usePresets } from "../../presets/presets.hook";

const AppointmentWrapper = styled.div`
  .button {
    padding: 0.2em 0.8em;
    float: right;
  }
`;

export const AppointmentMessage = (props: {
  message: { id: string; message: string; booked?: boolean };
}) => {
  const { message } = props;
  const messageParts = message.message.split("\n");
  const dateRow =
    messageParts.find((part) => part.includes("Date: "))?.split(" ") || [];
  const timeRow =
    messageParts.find((part) => part.includes("Time: "))?.split(" ") || [];
  const dateParts = dateRow[1]?.split("T");
  const { appointment } = usePresets(globalAppState?.auth?.user?.uid);
  // const appointmentDate = new Date(dateRow?.[1] ?? "");

  const [newDate, setNewDate] = useState<string>(dateParts[0]);
  const [newTime, setNewTime] = useState<string>(`${timeRow[1]} ${timeRow[2]}`);

  const { scheduleAt, dateOffset1, dateOffset2 } = useMemo(() => {
    const { timeZone } = globalAppState.profile;
    const scheduleAt = moment
      .tz(`${newDate} ${newTime}`, "YYYY-MM-DD hh:mm a", timeZone)
      .toDate();

    const dateOffset1 = new Date(scheduleAt).setMinutes(
      scheduleAt.getMinutes() - 60
    );
    const dateOffset2 = new Date(scheduleAt).setDate(scheduleAt.getDate() - 1);
    return { scheduleAt, dateOffset1, dateOffset2 };
  }, [newDate, newTime]);

  const scheduleNewAppointment = () => {
    const { timeZone } = globalAppState.profile;
    const { user } = globalAppState.auth;
    const { currentChat } = globalAppState.chat;

    removeScheduledByClientPhone(user.uid, currentChat);

    // const appointmentSequence = [
    //   {
    //     ...appointment.messages[0],
    //     date: new Date(),
    //     message: appointment.messages[0].message
    //       .split("{{date}}")
    //       .join(
    //         moment.tz(scheduleAt, timeZone).format("dddd, MMMM Do YYYY, h:mm a")
    //       ),
    //   },
    //   {
    //     ...appointment.messages[1],
    //     date: dateOffset2,
    //   },
    //   {
    //     ...appointment.messages[2],
    //     date: dateOffset1,
    //   },
    // ];
    const appointmentSequence = appointment.messages.map(
      (entry: any, index: number) => {
        const newEntry = Object.assign({}, entry);
        if (newEntry.message.includes("{{date}}")) {
          newEntry.message = newEntry.message
            .split("{{date}}")
            .join(
              moment
                .tz(scheduleAt, timeZone)
                .format("dddd, MMMM Do YYYY, h:mm a")
            );
        }
        const reminderDate = new Date(scheduleAt);
        if (newEntry.offset) {
          reminderDate.setDate(reminderDate.getDate() + newEntry.offset);
          newEntry.offset = undefined;
        }
        if (newEntry.offsetMinutes) {
          reminderDate.setMinutes(
            reminderDate.getMinutes() + newEntry.offsetMinutes
          );
          newEntry.offsetMinutes = undefined;
        }
        newEntry.date = index === 0 ? new Date() : reminderDate;
        return newEntry;
      }
    );

    scheduleSequence(appointmentSequence);

    set(
      ref(
        firebaseDb,
        `/clients/${user.uid}/${currentChat}/messages/${message.id}`
      ),
      {
        ...message,
        booked: true,
        message: messageParts.reduce((acc, part) => {
          if (part.includes("Date: ")) {
            const dateTime = moment.tz(
              `${newDate} ${newTime}`,
              "YYYY-MM-DD hh:mm a",
              timeZone
            );
            acc += `Date: ${dateTime.toISOString()}\n`;
          } else if (part.includes("Time: ")) {
            acc += `Time: ${newTime}\n`;
          } else {
            acc += `${part}\n`;
          }
          return acc;
        }, ""),
      }
    ).catch((err) => console.error(err));
  };

  return (
    <AppointmentWrapper>
      {messageParts.map((entry) => {
        const [key, value] = entry.split(": ");

        if (!key || !value || value === "undefined") return null;

        return (
          <p key={key}>
            <strong>{key}:</strong>{" "}
            {key === "Date" && (
              <input
                type="date"
                value={newDate}
                className="base-input"
                onChange={(event) => setNewDate(event.target.value)}
              />
            )}
            {key === "Time" && (
              <select
                className="base-input"
                value={newTime}
                onChange={(event) => setNewTime(event.target.value)}
              >
                <option value="8:00 am">8:00 am</option>
                <option value="9:00 am">9:00 am</option>
                <option value="10:00 am">10:00 am</option>
                <option value="11:00 am">11:00 am</option>
                <option value="12:00 noon">12:00 noon</option>
                <option value="1:00 pm">1:00 pm</option>
                <option value="2:00 pm">2:00 pm</option>
                <option value="3:00 pm">3:00 pm</option>
                <option value="4:00 pm">4:00 pm</option>
                <option value="5:00 pm">5:00 pm</option>
              </select>
            )}
            {!["Date", "Time"].includes(key) ? value : ""}
          </p>
        );
      })}
      <div>
        <a
          href={`https://sms-web-client.web.app/add-to-calendar.html?d=${scheduleAt.getTime()}`}
          target="_blamk"
        >
          Add to Calendar
        </a>
      </div>
      {!message.booked && (
        <div>
          <button
            tabIndex={0}
            onClick={scheduleNewAppointment}
            className="button"
          >
            Confirm
          </button>
        </div>
      )}
    </AppointmentWrapper>
  );
};
