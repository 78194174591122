import { useState } from "react";
import { firebaseDb } from "../firebase/firebase";
import { get, ref, set } from "firebase/database";
import { useStore } from "../store";

export const useAdmin = () => {
  const [loading, setLoading] = useState(false);
  const { state, updateState } = useStore("admin", {
    profiles: [],
  });

  const getProfiles = async () => {
    setLoading(true);

    const profiles = await get(ref(firebaseDb, "/profiles")).then(
      (snapshot) => {
        const data = snapshot.val();
        return data;
      }
    );

    const numRevMap = await get(ref(firebaseDb, "/numRevMap")).then(
      (snapshot) => {
        const data = snapshot.val();
        return data;
      }
    );

    const messageStats = await get(ref(firebaseDb, "/messageStats")).then(
      (snapshot) => {
        const data = snapshot.val();
        return data;
      }
    );

    updateState({
      profiles,
      numRevMap,
      messageStats,
    });

    setLoading(false);
  };

  const addProfile = async ({
    email,
    password,
    phone,
    timeZone,
    notifyPhoneList,
  }: any) => {
    const newProfileRef = ref(firebaseDb, `/profiles/${phone}`);

    setLoading(true);
    try {
      await set(newProfileRef, {
        email,
        password,
        phone,
        timeZone,
        notifyPhoneList,
      });
    } catch (err) {
      console.error(err);
      alert("Failed to add new profile! Please try again.");
    }
    setLoading(false);
  };

  const removeProfile = async (phone: string) => {
    const deleteRef = ref(firebaseDb, `/profiles/${phone}/delete`);

    setLoading(true);
    try {
      await set(deleteRef, true);
    } catch (err) {
      console.error(err);
      alert("Failed to delete profile! Please try again.");
    }
    setLoading(false);
  };

  const setMessageLimit = (uid: string, limit: number) => {
    return set(ref(firebaseDb, `/messageStats/${uid}/limit`), limit);
  };

  const setContactsLimit = (uid: string, limit: number) => {
    return set(ref(firebaseDb, `/messageStats/${uid}/contactsLimit`), limit);
  };

  const setStoredLimit = (uid: string, limit: number) => {
    return set(ref(firebaseDb, `/messageStats/${uid}/storedLimit`), limit);
  };

  const setSequenceLimit = (uid: string, limit: number) => {
    return set(ref(firebaseDb, `/messageStats/${uid}/sequenceLimit`), limit);
  };

  const setTagsLimit = (uid: string, limit: number) => {
    return set(ref(firebaseDb, `/messageStats/${uid}/tagsLimit`), limit);
  };

  const toggleFeature = (userUid: string, feature: string, flag: boolean) => {
    return set(
      ref(firebaseDb, `/messageStats/${userUid}/features/${feature}`),
      flag
    );
  };

  const setNotifyPhoneList = (uid: string, list: string) => {
    return set(ref(firebaseDb, `/messageStats/${uid}/notifyPhoneList`), list);
  };

  return {
    loading,
    admin: state,
    updateAdmin: updateState,
    getProfiles,
    addProfile,
    removeProfile,
    setMessageLimit,
    setContactsLimit,
    setStoredLimit,
    setSequenceLimit,
    setTagsLimit,
    toggleFeature,
    setNotifyPhoneList,
  };
};
